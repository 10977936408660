import React from 'react'
import UserIcon from '../assets/user_icon.png';

function Card({ totalOfDocuments }) {
    return (
        <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-#E4E4E4 dark:border-gray-700 flex ">
            <h2>
                <img src={UserIcon} alt="" /></h2>
            <h1 className="flex p-2 mb-2 text-6xl font-bold tracking-tight text-gray-900 dark:text-[#3F3E3E]"> {totalOfDocuments}</h1>
        </div>
    )
}

export default Card
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useHttpClient } from '../hooks/http-hook';
import UploadFile from '../components/uploadfile'
import { CircularProgress } from '@chakra-ui/react'

function AddDocument() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const jwt = JSON.parse(localStorage.getItem('data'));
    const [showpro, setShowPro] = useState(false);

    const [acivityServices, setAcivityServices] = useState([]);
    const [files, setFiles] = useState([]);

    let id = '';
    if (jwt) {
        id = jwt[Object.keys(jwt)[0]];
    }

    useEffect(() => {
        const url =
            process.env.REACT_APP_BACKEND +
            'api/groups/' +
            process.env.REACT_APP_BACKEND_ACTIVITY_ID;

        const fechActivity = async () => {
            try {
                const responseData = await sendRequest(url, 'GET', null, {
                    Authorization: 'Bearer ' + jwt['token'],
                });
                console.log('responseData.groups', responseData.groups)
                setAcivityServices(responseData.groups);
            } catch (error) {
                console.log(error);
            }
        };
        fechActivity();
    }, [sendRequest]);

    const handlAddDocument = async (data) => {
        data.notes = files.toString()
        console.log('data', data);
        console.log('files', files);

        const url = process.env.REACT_APP_BACKEND + 'api/documents/';
        try {
            const responseData = await sendRequest(
                url,
                "POST",
                JSON.stringify({
                    name: data.name,
                    name_Arb: data.name_Arb,
                    notes: data.notes,
                    age: data.age,
                    gender: data.gender,
                    nationality: data.nationality,
                    UNHCR_ID_no: data.UNHCR_ID_no,
                    Personal_UNHCR: data.Personal_UNHCR,
                    Id_Number: data.Id_Number,
                    Phone1: data.Phone1,
                    Phone2: "",
                    Adderess: data.Adderess,
                    Date: "111",
                    Disability_Type: data.Disability_Type,
                    creatorId: id,
                    activityId: process.env.REACT_APP_BACKEND_ACTIVITY_ID,
                    groupId: data.groupId,
                    action: false
                }),
                {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt['token'],
                }
            );
            console.log(responseData, 'responseData');
            localStorage.setItem("data", JSON.stringify({ responseData }));

            if (localStorage.getItem("data").length > 0) {
                // console.log(responseData, 'xaxaada');
                navigate('/');
            }

            // auth.login(responseData.userId, responseData.token, responseData.state);
            // history.push(`/jobseeker/${responseData.userId}/dashboard`);
        } catch (error) {
            console.log(error, 'error');
        }
    };

    return (
        // <div className={'homepage w-screen h-screen '}>

        <div className="flex items-center justify-center ">
            {/* {!isLoading && error && <p>{error}</p>
            } */}
            <div className="flex justify-center p-10 m-3 mx-auto mt-10 bg-[#0000009e] border-gray-800 rounded-lg">
                <form onSubmit={handleSubmit(handlAddDocument)}>
                    <div className="p-4 mx-auto text-center h-11 mb-11 ">
                        <span class="bg-[#861F41] text-white  text-lg font-medium inline-flex items-center px-2.5 py-1.5 rounded mr-2 ">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="36.243"
                                height="36.437"
                                viewBox="0 0 36.243 36.437"
                                className="mr-2"
                            >
                                <path
                                    id="_8726390_user_icon_1_"
                                    data-name="8726390_user_icon (1)"
                                    d="M26.221,20.964a10.622,10.622,0,1,0-13.135,0A17.7,17.7,0,0,0,2.075,35.445a1.781,1.781,0,0,0,3.541.389,14.162,14.162,0,0,1,28.147,0,1.77,1.77,0,0,0,1.77,1.576h.195a1.77,1.77,0,0,0,1.558-1.947A17.7,17.7,0,0,0,26.221,20.964Zm-6.568-1.257a7.081,7.081,0,1,1,7.081-7.081A7.081,7.081,0,0,1,19.654,19.707Z"
                                    transform="translate(-1.563 -1.495)"
                                    fill="#fff"
                                    stroke="#fff"
                                    stroke-width="1"
                                />
                            </svg>
                            New Beneficiaries
                        </span>
                    </div>
                    <div className="flex flex-row">
                        <div className="px-3 mb-6 md:w-auto md:mb-2 ">
                            <label
                                className="block mb-2 text-xs font-bold tracking-wide text-white"
                                for="grid-city"
                            >
                                Beneficiaries Name
                            </label>
                            <input
                                {...register('name', { required: true })}
                                className="block w-full px-4 py-3 text-black border rounded appearance-none bg-grey-lighter border-grey-lighter"
                                id="grid-city"
                                type="text"
                                placeholder="Muhammad"
                                aria-invalid={errors.name ? 'true' : 'false'}
                            />
                        </div>
                        <div className="px-3 mb-6 md:w-auto md:mb-2 ">
                            <label
                                className="block mb-2 text-xs font-bold tracking-wide text-white"
                                for="grid-city"
                            >
                                Beneficiaries Name In Arabic
                            </label>
                            <input
                                {...register('name_Arb', { required: true })}
                                className="block w-full px-4 py-3 text-black border rounded appearance-none bg-grey-lighter border-grey-lighter"
                                id="grid-city"
                                type="text"
                                placeholder="Muhammad"
                            />
                        </div>
                        <div className="px-3 md:w-auto ">
                            <label
                                className="block mb-2 text-xs font-bold tracking-wide text-white"
                                for="grid-zip"
                            >
                                ID number
                            </label>
                            <input
                                {...register('Id_Number', { required: true })}
                                className="block w-full px-4 py-3 text-black border rounded appearance-none bg-grey-lighter border-grey-lighter"
                                id="grid-zip"
                                type="text"
                                placeholder="9021000000"
                            />
                        </div>
                        <div className="px-3 mb-6 md:w-auto md:mb-2 ">
                            <label
                                className="block mb-2 text-xs font-bold tracking-wide text-white"
                                for="grid-city"
                            >
                                City
                            </label>
                            <input
                                {...register('Adderess')}
                                className="block w-full px-4 py-3 text-black border rounded appearance-none bg-grey-lighter border-grey-lighter"
                                id="grid-city"
                                type="text"
                                placeholder="Amman"
                            />
                        </div>
                    </div>

                    <div className="flex flex-row">
                        <div className="px-3 mb-6 md:w-auto md:mb-2 ">
                            <label
                                className="block mb-2 text-xs font-bold tracking-wide text-white"
                                for="grid-city"
                            >
                                Phone Number
                            </label>
                            <input
                                {...register('Phone1', { required: true })}
                                className="block w-full px-4 py-3 text-black border rounded appearance-none bg-grey-lighter border-grey-lighter"
                                id="grid-city"
                                type="text"
                                placeholder="0780000008"
                            />
                        </div>
                        <div className="flex flex-row">
                            <div className="px-3 mb-6 w-28 md:mb-2">
                                <label
                                    className="block mb-2 text-xs font-bold tracking-wide text-white"
                                    for="grid-city"
                                >
                                    Age
                                </label>
                                <input
                                    {...register('age', { required: true })}
                                    className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-gray-darker border-grey-lighter"
                                    id="grid-city"
                                    type="number"
                                    placeholder="30"
                                />
                            </div>
                            <div className="px-3 mb-6 w-28 md:mb-2 ">
                                <label
                                    className="block mb-2 text-xs font-bold tracking-wide text-white"
                                    for="grid-city"
                                >
                                    Gender
                                </label>

                                <select
                                    {...register('gender')}
                                    id="gender"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded py-3 px-3 focus:ring-[#861F41] focus:border-[#861F41] block w-full p-2.5   "
                                >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                            <div className="px-3 mb-6 md:mb-2 ">
                                <label
                                    className="block mb-2 text-xs font-bold tracking-wide text-white"
                                    for="grid-city"
                                >
                                    Nationality
                                </label>

                                <select
                                    {...register('nationality')}
                                    id="nationality"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded py-3 px-3 focus:ring-[#861F41] focus:border-[#861F41] block w-full p-2.5   "
                                >
                                    <option value="Jordanian">Jordanian</option>
                                    <option value="Syrian">Syrian</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="px-3 md:w-auto ">
                            <label
                                className="block mb-2 text-xs font-bold tracking-wide text-white"
                                for="grid-zip"
                            >
                                UNHCR ID Number
                            </label>
                            <input
                                {...register('UNHCR_ID_no', { required: true })}
                                className="block w-full px-4 py-3 text-black border rounded appearance-none bg-grey-lighter border-grey-lighter"
                                id="grid-zip"
                                type="text"
                                placeholder="9021000000"
                            />
                        </div>
                        <div className="px-3 mb-6 md:w-auto md:mb-2 "></div>
                    </div>

                    <div className="flex flex-row">
                        <div className="px-3 md:w-auto ">
                            <label
                                className="block mb-2 text-xs font-bold tracking-wide text-white"
                                for="grid-zip"
                            >
                                Personal UNHCR Number
                            </label>
                            <input
                                {...register('Personal_UNHCR')}
                                className="block w-full px-4 py-3 text-black border rounded appearance-none bg-grey-lighter border-grey-lighter"
                                id="grid-zip"
                                type="text"
                                placeholder="9021000000"
                            />
                        </div>

                        <div className="px-3 mb-6 md:mb-2 ">
                            <label
                                className="block mb-2 text-xs font-bold tracking-wide text-white"
                                for="grid-city"
                            >
                                Disability Type
                            </label>

                            <select
                                {...register('Disability_Type')}
                                id="Disability_Type"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded py-3 px-3 focus:ring-[#861F41] focus:border-[#861F41] block w-full p-2.5   "
                            >
                                <option value="Hearing">Hearing</option>
                                <option value="Visual">Visual</option>
                                <option value="Multi_hearing_visual">hearing and visual</option>
                                <option value="Other_Multi">Other Multi</option>
                            </select>
                        </div>
                        <div className="px-3 mb-6 md:w-auto md:mb-2">
                            <label
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                for="user_avatar"

                            >
                                Attachments
                                {/* <input
                                    className="block w-full px-4 py-3 text-black border rounded appearance-none bg-grey-lighter border-grey-lighter"
                                    aria-describedby="user_avatar_help"
                                    id="user_avatar"
                                    type="file"
                                /> */}
                                <UploadFile
                                    setLinks={setFiles} />
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="px-3 mt-5 mb-6 md:w-auto md:mb-2">
                            {/* <label
                                htmlFor="notes"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Notes
                            </label>
                            <textarea
                                {...register('notes', { required: true })}
                                id="notes"
                                rows="4"
                                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Write your thoughts here..."
                            ></textarea> */}
                        </div>
                        <div className="px-3 md:w-auto ">
                            {/* <label
                                className="block mb-2 text-xs font-bold tracking-wide text-white"
                                for="grid-zip"
                            >
                                Services
                            </label>
                            <ul className="w-48 text-sm font-medium text-[#000000] bg-white rounded-lg border border-gray-200 dark:bg-white dark:border-gray-600 ">

                                {acivityServices && acivityServices.map((service) =>
                                    <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                                        <div className="flex items-center pl-3">
                                            <input
                                                id="vue-checkbox"
                                                type="checkbox"
                                                value=""
                                                className="w-4 h-4 text-[#861F41]  bg-gray-100 rounded border-gray-300 focus:ring-[#861F41]  focus:ring-1 accent-[#861F41]"
                                            />
                                            <label
                                                for="vue-checkbox"
                                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-[#000000]"
                                            >

                                                {service.name}
                                            </label>
                                        </div>
                                    </li>
                                )

                                }</ul> */}

                            <div className="px-3 mb-6 md:mb-2 ">
                                <label
                                    className="block mb-2 text-xs font-bold tracking-wide text-white"
                                    for="grid-city"
                                >
                                    Services
                                </label>

                                <select
                                    {...register('groupId ')}
                                    id="groupId"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded py-3 px-3 focus:ring-[#861F41] focus:border-[#861F41] block w-full p-2.5   "
                                >
                                    {acivityServices && acivityServices.map((service) =>
                                        <option value={service.id}>{service.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        {/* <div className=''  >
                            <UploadFile
                            // setLinks={setLinks} links={links} 
                            />
                        </div> */}
                    </div>
                    <div className="flex flex-row">
                        <div className="px-3 mb-6 md:w-auto md:mb-2 ">
                            <button
                                type="submit"
                                class="text-gray-900 bg-white border border-gray-300  focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-3xl	 text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 "
                            >
                                {showpro && <CircularProgress isIndeterminate color='green.300' />}
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddDocument;

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SearchBar({ options, onInputChange }) {
    const ulRef = useRef();
    const inputRef = useRef();
    const idRef = useRef();
    let docId = ''
    const navigate = useNavigate();

    // useEffect(() => {
    //     inputRef.current.addEventListener('click', (event) => {
    //         event.stopPropagation();
    //         ulRef.current.style.display = 'flex flex-col';
    //         onInputChange(event);
    //     });
    //     document.addEventListener('click', (event) => {
    //         ulRef.current.style.display = 'none';
    //     });
    // }, [onInputChange]);

    const handleSearch = (e) => {
        e.preventDefault();
        console.log(inputRef.current.value, "docId");
        if (inputRef.current.value) {
            navigate(`/document/${inputRef.current.value}`);
        }
    }
    const [showModal, setShowModal] = useState(false);

    console.log('options', options)
    return (
        <div className="relative flex w-2/4 mx-auto" >
            {/* <input
                id="search-bar"
                type="text"
                placeholder="Search"
                ref={inputRef}
                onChange={onInputChange}
                className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-[#861F41] focus:border-[#861F41] dark:bg-[#E4E4E4] dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-[#3F3E3E] "
            /> */}

            {/* <ul id="results" className="absolute " ref={ulRef}>
                {options.map((option, index) => {
                    return (
                        <button
                            type="button"
                            key={index}
                            onClick={(e) => {
                                inputRef.current.value = option;
                            }}
                            className="list-group-item list-group-item-action"
                        >
                            {option}
                        </button>
                    );
                })}
            </ul> */}
            <button
                className="hover:text-[#861F41] top-0 right-0 p-2.5 text-sm font-medium text-white bg-[#861F41] rounded-r-lg border border-[#861F41] hover:bg-[#861F41] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-[#861F41] dark:hover:bg-[#E4E4E4] dark:focus:ring-[#E4E4E4]"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Search For Documents

            </button>
            {/* <button type="submit" className="hover:text-[#861F41] top-0 right-0 p-2.5 text-sm font-medium text-white bg-[#861F41] rounded-r-lg border border-[#861F41] hover:bg-[#861F41] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-[#861F41] dark:hover:bg-[#E4E4E4] dark:focus:ring-[#E4E4E4]">
                <svg aria-hidden="true" className="w-5 h-5 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                <span className="sr-only">Search</span>
            </button> */}

            {showModal ? (
                <>
                    <form onSubmit={handleSearch}
                        className="justify-center items-center flex overflow-x-hidden overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Search
                                    </h3>

                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                    <input
                                        id="search-bar"
                                        type="text"
                                        placeholder="Search"
                                        ref={inputRef}
                                        onChange={onInputChange}
                                        className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-[#861F41] focus:border-[#861F41] dark:bg-[#E4E4E4] dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-[#3F3E3E] "
                                    />
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto overflow-scroll ">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        <ul class="block py-1 text-sm text-gray-700 " aria-labelledby="dropdownDefault">
                                            {options.map((option, index) => {
                                                return (
                                                    <li
                                                        key={index}
                                                        onClick={(e) => {
                                                            inputRef.current.value = option.id;

                                                        }}
                                                        className=" p-2 mb-2"
                                                    >
                                                        {/* <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a> */}

                                                        {option.name}
                                                    </li>
                                                );
                                            })}


                                        </ul>
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button type="submit" className="hover:text-[#861F41] top-0 right-0 p-2.5 text-sm font-medium text-white bg-[#861F41] rounded-r-lg border border-[#861F41] hover:bg-[#861F41] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-[#861F41] dark:hover:bg-[#E4E4E4] dark:focus:ring-[#E4E4E4]">
                                        <svg aria-hidden="true" className="w-5 h-5 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http:www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                        <span className="sr-only">Search</span>
                                    </button>
                                    {/* <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Save Changes
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </div >
    )
}

export default SearchBar
import './App.css';
import Login from './Pages/Login';
import { Routes, Route } from "react-router-dom";
import Home from './Pages/Home';
import AddDocument from './Pages/AddDocument';
import AddService from './Pages/AddService';
import NavBar from './components/NavBar';
import DocumentPage from './Pages/DocumentPage';

function App() {
  return (
    // <div className="bg-[#861F41] w-screen h-screen ">
    <div className="w-screen h-screen homepage ">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/add-document" element={<AddDocument />} />
        <Route path="/add-service" element={<AddService />} />
        <Route path="/document/:id" element={<DocumentPage />} />
      </Routes>
    </div>
  );
}

export default App;




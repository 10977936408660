import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Logo from '../assets/iocc.png'

function NavBar() {
    const [isLogin, setIsLogin] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("data")) {
            setIsLogin(true)
        }
    }, [])

    const handleLogout = (e) => {
        e.preventDefault();

        if (localStorage.getItem("data").length > 0) {
            localStorage.removeItem("data")
            navigate('/login');
        }
    }
    return (
        <div class='relative flex flex-col justify-center overflow-hidden'>
            <div class="flex items-center justify-between  bg-[#861F41] p-3">
                <div class="flex items-center space-x-2 rounded shadow-md">
                </div>
                <div class="text-lg font-bold ml-20 text-gray-100 "> <a href='/' >< img src={Logo} alt="Logo" className='w-[400] h-20 opacity-100' /></a>   </div>
                <div class="flex items-center space-x-5 text-gray-100">
                    {
                        isLogin &&
                        <button onClick={handleLogout} type="button" class="border-white border-[1px] b mr-[63px] text-white hover:text-[#861F41] bg-[#861F41] hover:bg-[#E4E4E4] focus:outline focus:ring-4  font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-[#861F41] dark:hover:bg-[#E4E4E4] dark:focus:ring-[#861F41] w-4/2">Log Out</button>

                    }
                </div>
            </div>
        </div>
    )
}

export default NavBar
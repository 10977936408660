import React from 'react'
import Main from '../components/Main'
import NavBar from '../components/NavBar'


function Home() {

    return (
        // <div className={'homepage '}>
        <div>
            <main className='mt-12'>
                <Main />
            </main>
        </div>
    )
}

export default Home
import React, { useState, useEffect } from 'react'
import Action from './Action'
import Card from './Card'
import SearchBar from './SearchBar'
import { useHttpClient } from '../hooks/http-hook';
// import Chart from 'chart.js/auto';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'
function Main() {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [searchVal, setSearchVal] = useState([]);
    const [totalOfDocuments, setTotalIOfDocuments] = useState(0);
    const jwt = JSON.parse(localStorage.getItem('data'));
    let id = '';
    if (jwt) {
        id = jwt[Object.keys(jwt)[0]];
    }

    const [options, setOptions] = useState([]);

    useEffect(() => {
        localStorage.removeItem("searchDoc");

        const fechActivity = async () => {
            try {
                const responseData = await sendRequest(
                    process.env.REACT_APP_BACKEND +
                    "api/activities/documents/" +
                    process.env.REACT_APP_BACKEND_ACTIVITY_ID,
                    "GET",
                    null,
                    {
                        Authorization: "Bearer " + jwt['token']
                    }
                );

                setOptions(responseData.documents);
                setTotalIOfDocuments(responseData.documents.length)

            } catch (error) {
                console.log('error', error)

            }
        };
        fechActivity();

    }, [sendRequest]);

    const onInputChange = (event) => {
        localStorage.setItem("searchDoc", JSON.stringify(options));

        setSearchVal(
            options.filter((option) => { return (option.name.toLowerCase().includes(event.target.value)) || (option.Id_Number == event.target.value) || (option.Phone1 == event.target.value) }));
    }

    return (
        <div>
            {!isLoading && searchVal && <SearchBar options={searchVal} onInputChange={onInputChange} >
            </SearchBar>
            }
            {/* {!isLoading && error && <Alert status='error' className="w-5">
                <AlertIcon />
                <AlertTitle>{error}</AlertTitle>

            </Alert>} */}


            {/* Card Component  */}
            <div className='items-center flex-nowrap '>
                <div className='mt-6   flex float-right mr-[340px] '>
                    <Card totalOfDocuments={totalOfDocuments} />
                </div>
                <div className='flex float-right mt-6 '>

                    <Action />
                </div>
            </div>

        </div>

    )
}

export default Main
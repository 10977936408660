import React, { useEffect, useState } from 'react'
import MPic from '../assets/pic-m.svg'
import { Link, useParams } from 'react-router-dom';
import { useHttpClient } from '../hooks/http-hook';

function DocumentPage() {
    const [view, setView] = useState(true)
    // const data = {
    //     name: "Muhammad Qasem",
    //     Id_Number: "084848854788487",
    //     Phone: "0788658886",
    //     Adderess: "Amman",
    //     Date: "17/12/2022",
    //     creatorId: "10",
    //     activityId: [{ id: "1" }],
    //     services: [{ name: "Job Coach", id: "1" }, { name: "Job ", id: "1" }],
    //     gender: "male",
    //     attachments: ["http://localhost:3000/add-document", "http://localhost:3000/add  "]


    // }
    const params = useParams();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const allDocuments = JSON.parse(localStorage.getItem('searchDoc'));
    console.log('allDocuments', allDocuments[0].id)
    const [data, setData] = useState({})


    useEffect(() => {
        allDocuments.map((element) => {
            if (element.id == params.id) {
                setData(element)
            }
        })
        // const fechActivity = async () => {
        // try {
        //     setData(
        //         allDocuments.map((element) => {
        //             if (element.id == params.id) {
        //                 setData(element)
        //             }
        //         })
        //     )
        //     // setData(
        //     //     allDocuments.filter((option) => { return (option.name.includes(params)) }));
        // } catch (error) { console.log('error', error) }
        // };
        // fechActivity();
    }, [params]);

    const [documentData, setDocumentData] = useState(data)

    const handleEdit = (e) => {
        e.preventDefault();
        console.log("data", data);
        console.log("data", documentData);
    }

    return (
        <>
            {data && <div className='flex'>
                <div class=" flex flex-row m-10 p-10 w-full bg-white rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700">
                    <div >
                        <h1 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                            Profile of {data.name}
                        </h1>
                        <img class="mb-3 w-24 h-24 rounded-full shadow-lg" alt="" src={MPic} />
                    </div>


                    <div class="basis-1/2  mx-20">
                        <div class="w-full text-white p-10 rounded-lg border shadow-md  dark:border-gray-500">
                            <div class="max-w-none format lg:format-lg format-red">
                                <h1>Name : {data.name}</h1>
                                <p class="lead">ID Number : {data.Id_Number}</p>
                                <p>Phone Number : {data.Phone1}</p>
                                <p>City : {data.Adderess}</p>
                            </div>
                        </div>
                    </div>
                    <div class="basis-1/2  mx-10">
                        <div class="w-full text-white p-10 rounded-lg border shadow-md  dark:border-gray-500">
                            <div class="max-w-none format lg:format-lg format-red">
                                <h1>Arabic Name : {data.name_Arb}</h1>
                                <p class="lead">Age : {data.age}</p>
                                <p>Nationality : {data.nationality}</p>
                                <p>Personal_UNHCR : {data.Personal_UNHCR}</p>
                                <p>Disability_Type : {data.Disability_Type}</p>
                            </div>
                        </div>
                    </div>
                    <div class="basis-3/2  mx-20">
                        <div class="max-w-none format lg:format-lg format-red">
                            <div class="w-full bg-white rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700">
                                <div class="sm:hidden">
                                    <label htmlFor="tabs" class="sr-only">Select tab</label>
                                    <select id="tabs" class="bg-gray-50 border-0 border-b border-gray-200 text-gray-900 sm:text-sm rounded-t-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        {/* <option>Statistics</option> */}
                                        <option>Services</option>
                                        {/* <option>Attachments</option> */}
                                    </select>
                                </div>
                                <ul class=" text-sm font-medium text-center text-gray-500 rounded-lg divide-x divide-gray-200 sm:flex dark:divide-gray-600 dark:text-gray-400" id="fullWidthTab" data-tabs-toggle="#fullWidthTabContent" role="tablist">

                                    <li class="w-full">
                                        <button id="services-tab" data-tabs-target="#services" type="button" role="tab" aria-controls="services" aria-selected="false" class="inline-block p-4 w-full bg-gray-50 hover:bg-gray-100 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" onClick={(e) => {
                                            setView(!view)
                                        }}>Attachments</button>
                                    </li>
                                    {/* <li class="w-full">
                                        <button id="attachments-tab" data-tabs-target="#attachments" type="button" role="tab" aria-controls="attachments" aria-selected="false" class="inline-block p-4 w-full bg-gray-50 rounded-tr-lg hover:bg-gray-100 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" onClick={(e) => {
                                            setView(!view)
                                        }}>Attachments</button>
                                    </li> */}
                                </ul>
                                <div id="fullWidthTabContent" class="border-t border-gray-200 dark:border-gray-600">
                                    <div className={`${view === false ? "invisible " : "visible"} bg-white rounded-lg md:p-8 dark:bg-gray-800 grid grid-cols-2 gap-8 p-4 mx-auto max-w-screen-xl text-gray-900 sm:grid-cols-3 xl:grid-cols-6 dark:text-white sm:p-8" id="services" role="tabpanel" aria-labelledby="services-tab`}>
                                        {/* <h2 class="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">We invest in the world’s potential</h2> */}
                                        <ul className={`${view === false ? "invisible " : "visible"}space-y-4 text-gray-500 dark:text-gray-400`} >
                                            {/* {documentData && documentData.groupId.map((data) => {
                                                return <li class="flex space-x-2" key={data.id}>
                                                    {console.log(data, "data")}

                                                    <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                                    <span class="font-light leading-tight">{data.name}</span>
                                                </li>
                                            })} */}
                                            <li>
                                                <a href={data.notes} target="_blank" rel='noreferrer' >Links</a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class=" p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="attachments" role="tabpanel" aria-labelledby="attachments-tab">
                                        <ul className={`${view === true ? "invisible" : "visible"} space-y-4 text-gray-500 dark:text-gray-400`}>
                                            {   /* <!-- List --> */}
                                            {/* {data && data.map((data) => {
                                                return <li class="flex space-x-2" key={data.id}>
                                                    <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                                    <a href={data} class="font-light leading-tight">
                                                        <span class="font-light leading-tight">Link</span>
                                                    </a >
                                                </li>
                                            })} */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <button
                            onClick={handleEdit}
                            className="block px-6 mt-3 text-lg font-semibold text-black bg-white border-2 border-gray-800 rounded-full shadow-xl w- border-white-100 hover:text-red-900 hover:bg-white hover:border-red-900"
                        >
                            Edit
                        </button> */}
                    </div>
                </div>
            </div >
            }
        </>

    )
}

export default DocumentPage
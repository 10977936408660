import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListOl } from '@fortawesome/free-solid-svg-icons'
import { useHttpClient } from '../hooks/http-hook';
import { useForm } from 'react-hook-form';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

function Action() {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showpro, setShowPro] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handlAddDocument = (e) => {
        e.preventDefault();
        console.log("Login");
        navigate('/add-document');
    }
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const jwt = JSON.parse(localStorage.getItem('data'));
    let id = '';
    if (jwt) {
        id = jwt[Object.keys(jwt)[0]];
    }

    const groupSubmitHandler = async (data) => {
        try {
            // console.log(data);
            // console.log(activityId);
            // console.log(auth.token);
            // console.log(process.env.REACT_APP_BACKEND_URL + "/groups/");
            setShowPro(true)
            const res = await sendRequest(
                process.env.REACT_APP_BACKEND + "api/groups/",
                "POST",
                JSON.stringify({
                    name: data.name,
                    name_Arb: data.name_Arb,
                    about_group: data.about_group,
                    startDate: data.startDate,
                    activityId: process.env.REACT_APP_BACKEND_ACTIVITY_ID,
                    creatorId: id,
                }),
                {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt['token'],
                }
            );
            setShowPro(false)
            setShowModal(false)

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='mt-6'>
            <button type="button" onClick={handlAddDocument} className="w-48	 text-[#861F41] bg-[#E4E4E4] hover:bg-[#861F41] focus:outline-none focus:ring-4 focus:ring-[#E4E4E4] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-[#E4E4E4] dark:hover:bg-[#861F41] dark:focus:ring-[#E4E4E4] dark:border-[#E4E4E4] hover:text-[#E4E4E4]  flex flex-nowrap"><svg className="w-6 h-6 mr-3 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg> Add Document</button>
            {/* <button type="button" onClick={handlAddService} className="">  {" "}</button> */}
            <button
                className="w-48	 text-[#861F41] bg-[#E4E4E4] hover:bg-[#861F41] focus:outline-none focus:ring-4 focus:ring-[#E4E4E4] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-[#E4E4E4] dark:hover:bg-[#861F41] dark:focus:ring-[#E4E4E4] dark:border-[#E4E4E4] hover:text-[#E4E4E4] flex flex-nowrap "
                type="button"
                onClick={() => setShowModal(true)}
            ><FontAwesomeIcon icon={faListOl} size="lg" className='w-6 h-6 mr-3 ' />
                Add Service
            </button>
            {showModal ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                    >
                        <div className="relative w-auto max-w-3xl mx-auto my-6">
                            {/*content*/}
                            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                                {/*header*/}
                                <form onSubmit={handleSubmit(groupSubmitHandler)}>
                                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                                        <h3 className="text-3xl font-semibold">
                                            Add new service to Livelihood Activity
                                        </h3>
                                        <button
                                            className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >

                                        </button>
                                    </div>
                                    <div className="relative flex-auto p-6">

                                        <label className="text-lg leading-relaxed text-black ">
                                            Service Name
                                            <input    {...register('name', { required: true })} type="text" id="service-name" class="block p-4 w-full text-gray-900  rounded-lg border  sm:text-md  bg-gray-300 border-[#861F41] placeholder-gray-600  focus:ring-blue-500 focus:border-blue-500" placeholder="Service Name" />
                                        </label>
                                        <label className="text-lg leading-relaxed text-black ">
                                            Service Name In Arabic
                                            <input    {...register('name_Arb', { required: true })} type="text" id="service-name" class="block p-4 w-full text-gray-900  rounded-lg border  sm:text-md  bg-gray-300 border-[#861F41] placeholder-gray-600  focus:ring-blue-500 focus:border-blue-500" placeholder="Service Name" />
                                        </label>
                                        <label className="text-lg leading-relaxed text-black ">
                                            Service Information
                                            <input    {...register('about_group', { required: true })} type="text" id="service-name" class="block p-4 w-full text-gray-900  rounded-lg border  sm:text-md  bg-gray-300 border-[#861F41] placeholder-gray-600  focus:ring-blue-500 focus:border-blue-500" placeholder="Service Name" />
                                        </label>
                                        <label className="my-4 text-lg leading-relaxed text-black">
                                            Starteing Date
                                            <input type="date" {...register('startDate', { required: true })} id="service-date" class="block p-4 w-full text-gray-900  rounded-lg border  sm:text-md  bg-gray-300 border-[#861F41]  placeholder-gray-600  focus:ring-blue-500 focus:border-blue-500" placeholder="Service Date" />
                                        </label>
                                        ` {/* <label className="my-4 text-lg leading-relaxed text-black">
                                            Target Number
                                            <input type="number" id="service-target" class="block p-4 w-full text-gray-900  rounded-lg border  sm:text-md  bg-gray-300 border-[#861F41]  placeholder-gray-600  focus:ring-blue-500 focus:border-blue-500" placeholder="Service Target Number" />
                                        </label>` */}
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                                        <button
                                            className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-[#861F41] hover:bg-[#ffffff] hover:text-[#95113c] hover:border-2 hover:border-[#95113c]  hover:shadow-lg focus:outline-none"
                                            type="submit"
                                        // onClick={() => setShowModal(false)}
                                        >
                                            Add Service
                                            {showpro && <CircularProgress isIndeterminate color='green.300' />}
                                        </button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </>
            ) : null}
        </div>
    )
}

export default Action
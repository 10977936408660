import React, { useState } from 'react';
// import Logo from '../assets/iocc.png';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useHttpClient } from "../hooks/http-hook";
import { CircularProgress } from '@chakra-ui/react'

function Login() {
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [showpro, setShowPro] = useState(false);

    // const handleLogin = (data) => {
    //     console.log(data);
    //     // navigate('/');
    // }

    const authSubmitHandler = async (data) => {
        const url = process.env.REACT_APP_BACKEND + "api/users/login"
        setShowPro(true)
        try {
            const responseData = await sendRequest(
                url,
                "POST",
                JSON.stringify({
                    email: data.email,
                    password: data.password,
                }),
                {
                    "Content-Type": "application/json",
                }
            );
            // console.log(responseData, 'responseData');
            localStorage.setItem("data", JSON.stringify(responseData));
            setShowPro(false)
            if (localStorage.getItem("data").length > 0) {
                console.log(responseData, 'xaxaada');
                navigate('/');
            }

            // auth.login(responseData.userId, responseData.token, responseData.state);
            // history.push(`/jobseeker/${responseData.userId}/dashboard`);
        } catch (error) {
            console.log(error, 'error');
        }
    };

    return (
        <>
            {/* <img className="h-auto pt-10 mx-auto w-96" src={Logo} alt="Logo" /> */}
            <form className="mt-8" onSubmit={handleSubmit(authSubmitHandler)}>
                <div className="max-w-lg mx-auto">
                    <div className="flex justify-center py-2">
                        <h1 className="mb-4 font-bold leading-none text-gray-900 text-m md:text-3xl lg:text-4xl dark:text-white">
                            Log In
                        </h1>
                    </div>
                    <div className="flex justify-center py-2">
                        <p class=" text-lg font-normal text-gray-500  dark:text-gray-400">
                            Enter your Email & password
                        </p>
                    </div>
                    <div className="py-2">
                        <input
                            {...register("email", { required: true })}
                            placeholder="Email"
                            type="text"
                            className="block w-full px-3 py-2 placeholder-gray-600 bg-white border-2 border-gray-300 rounded-lg shadow-md text-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                        />
                    </div>
                    <div className="py-2" x-data="{ show: true }">
                        <div className="relative">
                            <input
                                placeholder="Password"
                                type="password"
                                {...register("password", { required: true })}
                                className="text-md block px-3 py-2 rounded-lg w-full 
            bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md
            focus:placeholder-gray-500
            focus:bg-white 
            focus:border-gray-600  
            focus:outline-none font-[Poppins-Bold]"
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5"></div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <button
                            type='submit'
                            className="block px-6 mt-3 text-lg font-semibold text-white bg-[#861F41] border-2 border-gray-300 rounded-full shadow-xl w- border-white-100 hover:text-red-900 hover:bg-white hover:border-red-900"
                        >
                            {showpro && <CircularProgress isIndeterminate color='red.300' />}

                            Login
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Login;

